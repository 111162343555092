<template>
  <div style="height: 1000px">
    <HomeTeaser />
    <v-row class="my-12"  style="background-color: #E5EDF4;" v-if="!userStore.isLogged">
      <v-col cols="12" md="9">
      <RegisterUser v-if="!user" />
      </v-col>
      <v-col cols="12" md="3"  >
        <div  class="pa-4">
       
        <span v-for="item in sortedItems" :key="item.id">
      <span class="mr-6">
        <b class="text-green head18">
          {{ getItemTitle(item) }}
        </b>
        {{ getItemDescription(item) }}
  
        ({{ sharedDataStore.getTimeAgo(item.date) }})
      </span>
    </span>
          </div>
      </v-col>
    </v-row>
    <v-row class="mt-12" style="background-color: #E5EDF4;">
      <v-col cols="12" md="4" class="pa-6">
        <v-card elevation="0" class="px-4 pt-4">
          <v-row >
            <v-col cols="12" style="height: 420px;">
             <v-row style="border-bottom: 4px solid #E5EDF4;">
              <v-col cols="9"><h3>WaizmannTabelle</h3><h2>PZR-Flatrate | <span class="text-green">Neu</span></h2></v-col>
              <v-col cols="3"><v-icon style="font-size: 36px;">mdi-table-arrow-right</v-icon></v-col>
             </v-row>
             <v-row class="mt-4" style="border-bottom: 4px solid #E5EDF4;">
              <v-col cols="6">
                <img
          :src="require('@/assets/bestellung/pzrPicto.jpg')"
          style="width: 160px; float: left"
        />
              </v-col>
              <v-col cols="6" class="pl-4">Fordern Sie jetzt kostenlos die neue WaizmannTabelle | <b>PZR-Flatrate</b> für Ihr Prophylaxe-Team an. 
              </v-col>  
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12">
                <router-link to="/login/waizmanntabellen" class="noUnderline head18">
                  <b>jetzt kostenlos bestellen</b>
                </router-link>
              </v-col>
            </v-row>
              </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="pa-6">
        <router-link to="/webinare/webinar_recruiting_zahnarzt_zfa" style="text-decoration: none;">
          <v-card elevation="0" class="px-4 pt-4" style="cursor: pointer;">
            <v-row>
              <v-col cols="12" style="height: 420px;">
                <v-row style="border-bottom: 4px solid #E5EDF4;">
                  <v-col cols="9">
                    <h3>WaizmannWebinar</h3>
                    <h2>Recruiting mit Social Media <sup class="text-green">2025</sup></h2>
                  </v-col>
                  <v-col cols="3">
                    <v-icon style="font-size: 36px;">mdi-school</v-icon>
                  </v-col>
                </v-row>
                <v-row class="mt-4" style="border-bottom: 4px solid #E5EDF4;">
                  <v-col cols="6">
                    <img style="width: 160px" :src="require('@/assets/site/zacoach.jpg')" />
                    <ul v-if="termine.length > 0">
                      <li v-for="t in termine.slice(0, 3)" :key="t.wter_id">
                        <span v-if="t.wter_status == 0">{{ t.datum }}</span>
                        <span class="text-red" v-else-if="t.wter_status == 1">
                          {{ t.datum }} <br />bereits beendet
                        </span>
                      </li>
                      <li>
                        <router-link to="/webinare/webinar_recruiting_zahnarzt_zfa">
                          Jetzt anmelden
                        </router-link>
                      </li>
                    </ul>
                  </v-col>
                  <v-col cols="6" class="pl-4">
                    <b>Dipl.-Psych. Frank Frenzel</b>
                    <ul>
                      <li>Führender Zahnarzt-Coach in Deutschland</li>
                      <li>
                        Betreiber des Nr. 1 Youtube-Kanals für Praxis-Management und
                        -Kommunikation: @MeinZahnarztCoach
                      </li>
                      <li>
                        <router-link to="/webinare/webinar_recruiting_zahnarzt_zfa">
                          ... mehr dazu....
                        </router-link>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </router-link>
      </v-col>

      <v-col cols="12" md="4" class="pa-6">
        <v-card elevation="0" class="px-4 pt-4">
          <v-row >
            <v-col cols="12" style="height: 420px;">
             <v-row style="border-bottom: 4px solid #E5EDF4;">
              <v-col cols="9"><h3>WaizmannWebinar</h3><h2>Neue Anmeldungen</h2></v-col>
              <v-col cols="3"><v-icon style="font-size: 36px;">mdi-account</v-icon></v-col>
             </v-row>
        <v-row>
          <v-col cols="12">
        
            <span v-for="t in teilnehmer" :key="t.wteil_id">
              <span v-if="t.wteil_pers_type <= 1">Zahnarzt </span
              ><span v-if="t.wteil_pers_type == 2">ZFA </span>{{ t.user_ort }}
              |
            </span>
          </v-col>
        </v-row>
          
              </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { getCurrentInstance, ref, computed, onMounted } from "vue";
  import RegisterUser from "./../components/user/RegisterUser.vue";
  import BaseCalls from "@/services/BaseCalls";
  import HomeTeaser from "@/views/helper/HomeTeaser.vue";
  import { useUserStore } from "@/stores/user";
 
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const userStore = useUserStore();
  const user = computed(() => userStore.userData);
  const bestellungen = ref([]);
  const teilnehmer = ref([]);
  const termine = ref([]);

  const getTermine = async () => {
    try {
      const data = { wter_webinar_id: 3, action: "getWebinarTerminePerId" };
      const resp = await BaseCalls.postWebinar(data);
      if (resp.data && resp.data.termine) {
        termine.value = resp.data.termine;
      }
    } catch (err) {
      console.log("Fehler bei der Aktion:", err.message);
    }
  };

  const sortedItems = computed(() => {
    console.log('bestellungen.value:', bestellungen.value);
    console.log('teilnehmer.value:', teilnehmer.value);

    const bestellungenArray = Array.isArray(bestellungen.value) ? bestellungen.value : [];
    const teilnehmerArray = Array.isArray(teilnehmer.value) ? teilnehmer.value : [];

    const combinedItems = [
      ...bestellungenArray.map((bestellung) => ({
        type: 'bestellung',
        id: bestellung.bestell_id,
        user_ort: bestellung.user_ort,
        description: `WaizmannTabelle: ${sharedDataStore.getProduktText(bestellung.bestell_produkt_text)}`,
        date: bestellung.earliest_created_date,
      })),
      ...teilnehmerArray.map((teilnehmerItem) => ({
        type: 'teilnehmer',
        id: teilnehmerItem.id,
        user_ort: teilnehmerItem.user_ort,
        description: 'nimmt an Recruiting Webinar teil',
        date: teilnehmerItem.wteil_created_date,
      })),
    ];

    return combinedItems
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 9);
  });

  const getItemTitle = (item) => {
    if (item.type === 'bestellung') {
      return `Zahnarztpraxis ${item.user_ort}`;
    } else if (item.type === 'teilnehmer') {
      return `Zahnarztpraxis  ${item.user_ort}`;
    }
    return '';
  };

  const getItemDescription = (item) => {
    return item.description;
  };

  const fetchBestellungen = async () => {
    try {
      const response = await BaseCalls.getBestellungPost({
        action: "getBestellungen",
        select: "getShowBestellungen",
        limit: 8
      });
      if (Array.isArray(response.data)) {
        bestellungen.value = response.data;
      } else if (Array.isArray(response.data.bestellungen)) {
        bestellungen.value = response.data.bestellungen;
      } else {
        console.error('bestellungen data is not an array:', response.data);
        bestellungen.value = [];
      }
    } catch (error) {
      console.error('Error fetching bestellungen:', error);
      bestellungen.value = [];
    }
  };

  const fetchTeilnehmer = async () => {
    try {
      const response = await BaseCalls.postWebinar({
        action: "getTeilnehmerNeue20"
      });
      if (Array.isArray(response.data.teilnehmer)) {
        teilnehmer.value = response.data.teilnehmer;
      } else {
        console.error('teilnehmer data is not an array:', response.data);
        teilnehmer.value = [];
      }
    } catch (error) {
      console.error('Error fetching teilnehmer:', error);
      teilnehmer.value = [];
    }
  };

  onMounted(() => {
    const { proxy } = getCurrentInstance();
    proxy.$updateDocumentHead(
      "TeamWaizmann",
      "Service Zahnzusatzversicherung für Zahnärzte"
    );
    fetchBestellungen();
    fetchTeilnehmer();
    getTermine();
  });

</script>
